import { Illustration } from '@/shared/ui';

import { ItemRarityLiveFeedIconSvgProps } from './item-rarity-live-feed.types';

export const colorsByVariant = {
  common: 'var(--rarity-common-background100)',
  uncommon: 'var(--rarity-uncommon-background100)',
  'ultra-rare': 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  legendary: 'var(--rarity-legendary-background100)',
} as const;

export const colorsMM2Variant = {
  common: 'var(--rarity-legendary-background100)',
  uncommon: 'var(--rarity-common-background100)',
  unique: 'var(--rarity-uncommon-background100)',
  vintage: 'var(--rarity-ultra-rare-background100)',
  rare: 'var(--rarity-rare-background100)',
  godly: '#FE9920',
  ancient: '#E0E345',
  legendary: '#D41057',
} as const;

export const sizeItemRarityLiveFeed = {
  '120': 120,
  '100': 100,
  '78': 78,
  '72': 72,
  '58': 58,
  '52': 52,
} as const;

export const ItemRarityLiveFeed = ({
  variant = 'common',
  size,
  className,
  style,
  colorScheme,
  isMM2Variant,
}: ItemRarityLiveFeedIconSvgProps) => {
  return (
    <Illustration
      spanTagClassName={className}
      style={{
        color: colorScheme[variant],
        width: sizeItemRarityLiveFeed[size],
        height: sizeItemRarityLiveFeed[size],
        ...style,
      }}
      name={
        isMM2Variant ? 'item-rarity-live-feed-mm2' : 'item-rarity-live-feed'
      }
    />
  );
};
